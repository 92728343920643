import React, { useState } from "react"

import { Box, Button, Typography, useTheme } from "@mui/material"
import { Facebook, Instagram, LinkedIn } from "@mui/icons-material"
import { ClickAwayListener, Unstable_Popup as Popup } from "@mui/base"

import { StylelessLink } from "./Util"
import { Copyright } from "./Copyright"

import PicsLogo from "../assets/png/logo_pics.png"
import VRLogo from "../assets/png/logo_vr.png"
import SBCSLogo from "../assets/png/logo_sbcs.png"
import FACSLogo from "../assets/png/logo_facs.png"
import BDELogo from "../assets/png/Logo_BDE.png"
import StudioPouletLogo from "../assets/png/studiopoulet.png"
import HyrisBLogo from "../assets/png/Logo_Hyris_B.png"
import HyrisNLogo from "../assets/png/Logo_Hyris_N.png"
import HyrisBIcon from "../assets/png/Icon_Hyris_B.png"
import HyrisNIcon from "../assets/png/Icon_Hyris_N.png"

import { CONSTANTS } from "./Util"
import { useAuthContext } from "../services/auth"
import { useThemeContext } from "../services/ThemeContext"
import { UserRole } from "../services/auth/auth.types"

export const Footer = (): React.ReactElement => {
  const [explodePupopOpen, setExplodePupopOpen] = useState(false)

  const theme = useTheme()
  const { isAuth, role } = useAuthContext()
  const { setTheme } = useThemeContext()

  return (
    <Box
      component="footer"
      sx={{
        background: `linear-gradient(${theme.palette.element.footer.background} 20%, transparent)`,
        p: 6,
        pb: 32,
        borderTop: `1px solid ${theme.palette.element.footer.border}`,
        color: theme.palette.element.footer.text,
        "& .hover-fade": {
          transition: ".2s",
        },
        "& .hover-fade:hover": {
          opacity: 0.7,
        },
        "& .hover-fade-wrapper .hover-fade-target": {
          transition: ".2s",
        },
        "& .hover-fade-wrapper:hover .hover-fade-target": {
          opacity: 0.7,
        },
      }}
    >
      <Box display="flex" flexWrap="wrap" justifyContent="center" height="auto">
        <Box margin="20px" sx={{ width: "fit-content" }}>
          <Typography fontWeight="bold" marginBottom={2}>
            <StylelessLink to="/contact">{"Nous contacter"}</StylelessLink>
          </Typography>
          <Typography fontSize={12}>
            <StylelessLink to={`mailto:${CONSTANTS.contact.contact.mail}`} external>
              <span className="hover-fade">{"Informations diverses"}</span>
            </StylelessLink>
          </Typography>
          <Typography fontSize={12}>
            {" "}
            <StylelessLink to={`mailto:${CONSTANTS.contact.presta.mail}`} external>
              <span className="hover-fade">{"Demande de prestation"}</span>
            </StylelessLink>
          </Typography>
          <Typography fontSize={12}>
            <StylelessLink to={`mailto:${CONSTANTS.contact.prez.mail}`} external>
              <span className="hover-fade">{"Demande de couverture"}</span>
            </StylelessLink>
          </Typography>
          <Typography fontSize={12}>
            <StylelessLink to={`mailto:${CONSTANTS.contact.matos.mail}`} external>
              <span className="hover-fade">{"Prêt de matériel"}</span>
            </StylelessLink>
          </Typography>
          <Typography fontSize={12}>
            <StylelessLink to={`mailto:${CONSTANTS.contact.rej.mail}`} external>
              <span className="hover-fade">{"Demande de régie"}</span>
            </StylelessLink>
          </Typography>
          <Typography fontSize={12}>
            <StylelessLink to={`mailto:${CONSTANTS.contact.geek.mail}`} external>
              <span className="hover-fade">{"Signaler un bug"}</span>
            </StylelessLink>
          </Typography>
          {role === UserRole.HYRIS || role === UserRole.GEEK ? (
            <>
              {" "}
              <Typography fontSize={12}>
                <span
                  onClick={() => setExplodePupopOpen(true)}
                  id={`explode-button`}
                  style={{ cursor: "pointer" }}
                  className="hover-fade"
                >
                  {"Faire exploser Hyris"}
                </span>
              </Typography>
              <Popup
                anchor={document.querySelector(`#explode-button`)}
                open={explodePupopOpen}
                placement="top"
                offset={10}
                style={{ zIndex: 400 }}
              >
                <ClickAwayListener onClickAway={() => setExplodePupopOpen(false)}>
                  <Box padding={2} borderRadius="5px" sx={{ backgroundColor: "primary.main" }}>
                    <Typography>{"Etes-vous sûr de bien vouloir faire exploser Hyris !?"}</Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ margin: 1 }}
                      onClick={() => setExplodePupopOpen(false)}
                    >
                      {"Annuler"}
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => setTheme(2)} sx={{ margin: 1 }}>
                      {"Oui, faire exploser"}
                    </Button>
                  </Box>
                </ClickAwayListener>
              </Popup>
            </>
          ) : (
            <></>
          )}
        </Box>
        <Box margin="20px">
          <Typography fontWeight="bold" marginBottom={2} textAlign="center">
            {"Les autres assos qu'on aime bien"}
          </Typography>
          <Box display="flex" flexWrap="wrap" justifyContent="center" maxWidth="300px">
            <StylelessLink to="https://galerie.pics" external>
              <Box margin={1} display="flex" justifyContent="center" className="hover-fade-wrapper">
                <Typography fontSize={12} ml={1} display="flex" alignItems="center">
                  <img
                    src={PicsLogo}
                    alt="logo Pics"
                    width="70px"
                    style={{ filter: theme.palette.mode === "light" ? "invert(1)" : "invert(0)", marginRight: "10px" }}
                    className="hover-fade-target"
                  />
                  {"Asso photo de CentraleSupélec"}
                </Typography>
              </Box>
            </StylelessLink>
            <StylelessLink to="https://www.sbcs-events.fr" external>
              <Box margin={1} display="flex" justifyContent="center" className="hover-fade-wrapper">
                <Typography fontSize={12} ml={1} display="flex" alignItems="center">
                  <img
                    src={SBCSLogo}
                    alt="logo SBCS"
                    height="50px"
                    style={{ filter: theme.palette.mode === "light" ? "invert(1)" : "invert(0)", marginRight: "10px" }}
                    className="hover-fade-target"
                  />
                  {"Sono Barco de CentraleSupélec"}
                </Typography>
              </Box>
            </StylelessLink>
            <StylelessLink to="https://viarezo.fr" external>
              <Box margin={1} display="flex" justifyContent="center" className="hover-fade-wrapper">
                <Typography fontSize={12} ml={1} display="flex" alignItems="center">
                  <img
                    src={VRLogo}
                    alt="logo VR"
                    width="70px"
                    style={{ marginRight: "10px" }}
                    className="hover-fade-target"
                  />
                  {"Réseau de CentraleSupélec"}
                </Typography>
              </Box>
            </StylelessLink>
          </Box>
        </Box>
        <Box margin="20px" maxWidth="200px" textAlign="center">
          <Typography fontWeight="bold" marginBottom={2}>
            {"Nos partenaires"}
          </Typography>
          <StylelessLink to="https://www.facebook.com/federationACS/" external>
            <img src={FACSLogo} alt="logo FACS" height="60px" style={{ margin: "10px" }} className="hover-fade" />
          </StylelessLink>
          <StylelessLink to="https://bde.cs-campus.fr/" external>
            <img src={BDELogo} alt="logo BDE" height="60px" style={{ margin: "10px" }} className="hover-fade" />
          </StylelessLink>
          {isAuth() ? (
            <StylelessLink to="/video/1975">
              <img
                src={StudioPouletLogo}
                alt="Studio Poulet"
                height="60px"
                style={{ margin: "10px" }}
                className="hover-fade"
              />
            </StylelessLink>
          ) : (
            <></>
          )}
        </Box>
        <Box margin="20px" maxWidth="200px">
          <Typography fontWeight="bold" marginBottom={2}>
            {"A propos"}
          </Typography>
          <Typography fontSize={12} marginBottom={1}>
            {
              "Hyris est l'association audiovisuelle de CentraleSupélec, née en 2017 de la fusion de NX Télévision (école Centrale Paris) et de SMS (école Supérieure d'électricité)."
            }
          </Typography>
          <StylelessLink to="https://www.facebook.com/HyrisTV/" external>
            <Facebook
              style={{
                fontSize: "40px",
                fill: "#3B5998",
                stroke: "none",
              }}
              className="hover-fade"
            />
          </StylelessLink>
          <StylelessLink to="https://www.linkedin.com/company/hyris-tv/" external>
            <LinkedIn
              style={{
                fontSize: "40px",
                fill: "#0E76A8",
                stroke: "none",
              }}
              className="hover-fade"
            />
          </StylelessLink>
          <StylelessLink to="https://www.instagram.com/hyris_cs/" external>
            <Instagram
              style={{
                fontSize: "40px",
                fill: "#D62977",
                stroke: "none",
              }}
              className="hover-fade"
            />
          </StylelessLink>
        </Box>
        <Box margin="20px" maxWidth="200px">
          <Typography fontWeight="bold" marginBottom={2}>
            {"Ressources"}
          </Typography>
          <Typography fontSize={12}>
            {
              "En cas d'autorisation d'utilisation du logo de la part d'Hyris, vous pouvez le télécharger ici au format .png. Si vous cherchez d'autres ressources, n'hésitez pas à nous contacter."
            }
          </Typography>
          <a href={HyrisBLogo} download="Logo_Hyris_B.png">
            <img
              src={HyrisBIcon}
              alt="logo Hyris B"
              height="40px"
              style={{ borderRadius: "100vmin" }}
              className="hover-fade"
            />
          </a>
          <a href={HyrisNLogo} download="Logo_Hyris_N.png">
            <img
              src={HyrisNIcon}
              alt="logo Hyris N"
              height="40px"
              style={{ borderRadius: "100vmin" }}
              className="hover-fade"
            />
          </a>
        </Box>
      </Box>
      <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
        {"Hyris"}
      </Typography>
      <Copyright />
      <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
        {`v${CONSTANTS.version}`}
      </Typography>
    </Box>
  )
}
